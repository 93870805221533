<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Reward Wallets</h2>
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search For User name"
          class="mb-5"
          v-model="rewardWallet.searchQuery"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex flex-col">
        <div class="duration-300">
          <datatable
            :url="rewardWallet.url"
            :ajax="true"
            :ajax-pagination="true"
            :columns="rewardWallet.columns"
            :query="rewardWallet.searchQuery"
            :fillable="false"
            ref="table"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rewardWallet: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/wallet/reward`,
        searchQuery: '',
        columns: [
          {
            name: 'reward_walletable',
            th: 'Customer Name',
            render: ({ owner }) => `${owner?.name} ${owner?.last_name}`
          },
          {
            name: 'balance',
            th: 'Wallet Balance',
            render: wallet =>
              this.$options.filters.formatAmount(wallet?.balance)
          }
        ]
      })
    };
  }
};
</script>
